import { defineAsyncComponent, h } from 'vue';

import { mergeDeep } from '@/helpers/utils';
import { caption, openPanel } from '@/layouts/layouts';
import { getModel } from '@/tables';
import DBStoreRecord from '@/core/db_store_record';

export const edit = async (params: any = {}): Promise<any> => {
	return new Promise((resolve) => {
		const settings = Object.assign({
			table: '',
			copy: false,
			store: null,
			data: {},
			config: {},
			form: 'edit',
			owner: null,
			readonly: false,
			caption: null
		}, params);

		if (settings.store || settings.table) {
			const table = settings.store ? settings.store.name : settings.table;

			const schema = getModel(table);

			const editSchema = schema?.forms;

			//Секция form/edit
			const editForm = mergeDeep({}, editSchema ? editSchema[settings.form] : {}, settings.config);

			const config = mergeDeep({}, editForm?.config ? editForm.config : {});

			const store = new DBStoreRecord(table, config);

			if (settings?.store?.model?.depends && settings?.store?.model?.ownerField && settings?.store?.owner) {
				store.owner = settings.store.owner;
			}

			if (settings.store) {
				store.model = settings.store.model;
				store.files = settings.store.files;
			}

			if (settings.owner) {
				store.owner = settings.owner;
				config['owner'] = settings.owner;
			}

			const access = store.model.access;
			store.state.readonly = settings.readonly || !(access.create || access.update || access.delete);

			if (schema) settings.caption = settings.caption ? settings.caption : schema.description;

			openPanel(
				mergeDeep(
					{
						modal: caption.value == '',
						scrollable: !editForm?.component,
						caption: schema ? `${settings.copy ? 'Копирование' : params.data[schema.key] ? 'Редактирование' : 'Создание'} (${settings.caption})` : 'Редактирование',
						onCreate: (panel: any) => {
							return {
								component: h(
									editForm?.component ? editForm.component : defineAsyncComponent(() => import('@/tables/Edit.vue')),
									{
										panel,
										store,
										config,
										data: settings.data,
										copy: settings.copy,
										defaults: settings.defaults
									}
								)
							}
						},
						onClose: (result: any) => resolve(result)
					},
					editForm?.panel ? editForm.panel : {}
				)
			);

		} else resolve(null);
	})
}

export const editRecId = async (table: string, id: string, owner: any = null, config: any = {}): Promise<any> => {
	return id && edit(
		{
			table,
			data: { id },
			owner,
			config
		}
	);
}

export const newRec = async (table: string, data: any, owner: any = null, config: any = {}): Promise<any> => {
	return edit(
		{
			table,
			data: Object.assign(data, { id: null }),
			owner,
			config
		}
	);
}

export const selectComponet = (component: any, componentConfig: any = {}, panelConfig: any = {}): Promise<any> => {
	return new Promise((resolve) => {
		return openPanel(Object.assign({
			modal: true,
			caption: 'Выбор',
			onCreate: (panel: any) => ({
				component: h(
					component,
					{
						panel,
						config: componentConfig
					}
				)
			}),
			onClose: (result: any) => resolve(result)
		}, panelConfig));
	})
}

export const select = (table: string, id = '', owner: any = null, options: any = {}): Promise<any> => {
	return new Promise((resolve) => {
		const schema = getModel(table);

		if (!schema) resolve(null);

		const select = schema?.forms?.select;

		const config: any = Object.assign({ slectMode: true, id }, select?.config ? select.config : {}, options ? options : {});

		if (owner) config['owner'] = owner;

		const component = select?.component ? select.component : defineAsyncComponent(() => import('@/tables/Select.vue'));

		const panelConfig = Object.assign(select?.panel ? select.panel : {}, options?.panel ? options.panel : {});

		return openPanel(Object.assign({
			modal: true,
			caption: `Выбор (${schema.description})`,
			onCreate: (panel: any) => ({
				component: h(
					component,
					{
						table,
						panel,
						config
					}
				)
			}),
			onClose: (result: any) => resolve(result)
		}, panelConfig));
	})
}
