import country from "./country";
import contacts from "./contacts";
import contragents from "./contragents";
import contragents_departments from "./contragents_departments";
import brigades from "./brigades";
import brigades_list from "./brigades_list";
import call_log from "./call_log";
import employees from "./employees";
import files from "./files";
import files_versions from "./files_versions";
import patients from "./patients";
import templates from "./templates";
import icd10 from "./icd10";

import { mergeDeep } from "@/helpers/utils";

const tables: any = {
	country,
	brigades,
	contacts,
	contragents,
	'contragents.departments': contragents_departments,
	brigades_list,
	call_log,
	employees,
	templates,
	files,
	files_versions,
	icd10,
	patients
}

export const schema: any = {};

export const loadSchema = (dbSchema: any) => {
	for (const key in schema) delete schema[key];

	for (const key in dbSchema) {
		const table: any = tables[key] ? tables[key] : {};

		schema[key] = Object.assign({}, dbSchema[key], table);
	}

	for (const keySchema in schema) {
		const structure = schema[keySchema];

		const fields = structure.fields;

		const key = structure.key;

		if (!fields[key]) {
			fields[key] = {
				[key]: {
					description: 'id',
					type: 'UUID'
				}
			}
		}

		for (const keyField in fields) {
			const field = fields[keyField];

			if (typeof field.type == 'object') {
				if (field.type.table && typeof field.type.table == 'string') {
					field.table = field.type.table;
					field.type.table = schema[field.type.table];
					field.type.table.subtable = true;
					field.ownerField = field.type.table.ownerField;
				}
			}
		}
	}

	// console.log(schema);
}

export const getModel = (name: string) => {
	if (name) {
		const model = schema[name];
		if (model) {
			const _model = mergeDeep({}, model);

			//Если напрямую запрашивается модель табличной части, то удаляем признак "subtable"
			if (name.split('.').length > 1 && _model.subtable) delete _model.subtable;

			return _model;
		} else {
			return tables[name] ? mergeDeep({ offLine: true }, tables[name]) : null;
		}
	} else {
		return null
	}
}
