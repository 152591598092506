<script>
import { computed, defineComponent, onBeforeUpdate } from 'vue';

import DBStoreRecord from '@/core/db_store_record';
import { genGUID } from '@/helpers/utils';

export default defineComponent({
	props: {
		store: {
			type: Object,
			default: null
		},
		form: {
			type: Object,
			default: null
		},
		field: {
			type: String,
			default: null
		},
		readonlyForm: {
			type: Boolean,
			default: null
		},
		config: {
			type: Object,
			default: () => ({})
		}
	},

	setup(props, { slots }) {
		const stores = [];

		const createStores = () => {
			stores.splice(0, stores.length);

			if (props.store) {
				const store = props.store;

				store.data.rows.forEach((data, row) => {
					const storeRow = new DBStoreRecord(store.name);

					storeRow.data = data;

					storeRow.setWatching();

					stores.push({
						id: genGUID(),
						row,
						data,
						form: {
							store: storeRow,
							addValidation: props.form.addValidation,
							delValidation: props.form.delValidation,
							nextControl: props.form.nextControl,
							setField: props.form.setField,
							deleteField: props.form.deleteField,
							save: props.form.save,
							isNew: props.form.isNew,
							readonly: computed(() => typeof props.readonlyForm == 'boolean' ? props.readonlyForm : store.state.readonly)
						}
					});
				})

			} else {
				const store = props.form.store;

				const field = store.model.fields[props.field];

				if (!field) return null;

				const storeData = store.data[props.field];

				if (!storeData) return null;

				storeData.forEach((data, row) => {
					stores.push({
						id: data.id,
						row,
						data,
						form: {
							store: storeRow(field, data),
							addValidation: props.form.addValidation,
							delValidation: props.form.delValidation,
							nextControl: props.form.nextControl,
							setField: props.form.setField,
							deleteField: props.form.deleteField,
							save: props.form.save,
							isNew: props.form.isNew,
							readonly: computed(() => typeof props.readonlyForm == 'boolean' ? props.readonlyForm : store.state.readonly)
						}
					})
				});
			}
		}

		const storeRow = (field, data) => {
			const store = props.form.store;

			const storeRow = new DBStoreRecord(field.table);
			storeRow.model = field.type.table;

			storeRow.createState(Object.assign({}, store.state.fields[props.field], props.config));

			storeRow.files = store.files;
			storeRow.state.readonly = typeof props.readonlyForm == 'boolean' ? props.readonlyForm : store.state.readonly;

			storeRow.data = data;

			storeRow.setWatching();

			return storeRow;
		}


		onBeforeUpdate(() => {
			stores.forEach((el) => el.form.store.stopWatching());
		})

		createStores();

		return () => {
			createStores();

			return stores.map((el) => slots.default(el));
		}
	}
})

</script>

<style></style>